import UniversalStudiosInfo from "../Components/UniversalParks/UniversalStudiosInfo";
import QuoteForm from "../Components/Form/QuoteForm";

function UniversalStudios() {
  return (
    <>
        <UniversalStudiosInfo/>
        <QuoteForm/>
    </>
  );
}

export default UniversalStudios;