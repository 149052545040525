import IslandsOfAdventureInfo from "../Components/UniversalParks/IslandsOfAdventureInfo";
import QuoteForm from "../Components/Form/QuoteForm";

function IslandsOfAdventure() {
  return (
    <>
        <IslandsOfAdventureInfo/>
        <QuoteForm/>
    </>
  );
}

export default IslandsOfAdventure;