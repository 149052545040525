import DisneyHotelsInfo from "../Components/Hotels/DisneyHotelsInfo";
import QuoteForm from "../Components/Form/QuoteForm";

function DisneyHotels() {
  return (
    <>
        <DisneyHotelsInfo/>
        <QuoteForm/>
    </>
  );
}

export default DisneyHotels;