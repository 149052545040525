import MagicKingdomInfo from "../Components/DisneyParks/MagicKingdomInfo";
import QuoteForm from "../Components/Form/QuoteForm";

function MagicKingdom() {
  return (
    <>
        <MagicKingdomInfo/>
        <QuoteForm/>
    </>
  );
}

export default MagicKingdom;