import VolcanoBayInfo from "../Components/UniversalParks/VolcanoBayInfo";
import QuoteForm from "../Components/Form/QuoteForm";

function VolcanoBay() {
  return (
    <>
        <VolcanoBayInfo/>
        <QuoteForm/>
    </>
  );
}

export default VolcanoBay;