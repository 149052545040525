import EPCOTInfo from "../Components/DisneyParks/EPCOTInfo";
import QuoteForm from "../Components/Form/QuoteForm";

function EPCOT() {
  return (
    <>
        <EPCOTInfo/>
        <QuoteForm/>
    </>
  );
}

export default EPCOT;