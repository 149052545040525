import HollywoodStudiosInfo from "../Components/DisneyParks/HollywoodStudiosInfo";
import QuoteForm from "../Components/Form/QuoteForm";

function HollywoodStudios() {
  return (
    <>
        <HollywoodStudiosInfo/>
        <QuoteForm/>
    </>
  );
}

export default HollywoodStudios;