import AnimalKingdomInfo from "../Components/DisneyParks/AnimalKingdomInfo";
import QuoteForm from "../Components/Form/QuoteForm";

function AnimalKingdom() {
  return (
    <>
        <AnimalKingdomInfo/>
        <QuoteForm/>
    </>
  );
}

export default AnimalKingdom;